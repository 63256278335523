import { FC } from 'react';
import { CHEVRON_RIGHT_WHITE } from 'constants/icons';
import { StatusType } from 'constants/kyb';
import { ROUTES_PATH } from 'constants/routeConfig';
import { Text } from 'destiny/dist/components/atoms/text';
import { Button } from 'destiny/dist/components/molecules/button';
import { defaultFn } from 'destiny/dist/constants/index';
import { BUTTON_SIZE_TYPES, BUTTON_STATE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { useAppSelector } from 'hooks/toolkit';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { isIndividualMerchant } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import { ZeroListDataType } from 'components/banners/types';
import Player from 'components/DynamicLottiePlayer';

const ZeroListData: FC<ZeroListDataType> = ({
  handleClick = defaultFn,
  title = '',
  subtitle = '',
  buttonText = '',
  preKybButtonText = '',
  lottieJson = null,
  imageWrapperClassName = 'tw-w-[100px] tw-h-[100px]',
  imageWrapperStyle = '',
  imageSrc = null,
  lottieDimensions = {},
  destinyButtonProps = {},
  showButton = true,
  preKybSubtitle = '',
  wrapperClassName = '',
  contentClassName = 'tw-w-[280px]',
  titleClassName = 'tw-text-TEXT_SECONDARY tw-mt-6 f-20-400',
  subtitleClassName = 'tw-text-TEXT_SECONDARY tw-mt-6 f-16-300 tw-text-center',
  id = '',
  productKybStatus,
  buttonSize = BUTTON_SIZE_TYPES.MEDIUM,
}) => {
  const isIndividual = isIndividualMerchant();
  const router = useRouter();
  const { kybStatus } = useAppSelector((state: RootState) => state.kyb);

  const commonKybStatus = productKybStatus ?? kybStatus;
  const isPreKyb = commonKybStatus !== StatusType.SUCCESS;

  const subTitleData = isPreKyb ? preKybSubtitle : subtitle;

  const kybButtonText = 'Activate Account';
  const kybDestinyButtonProps = {
    trailingIconProps: { trailingIconClass: 'tw-ml-4' },
    customTrailingIcon: <Image src={CHEVRON_RIGHT_WHITE} alt='right' width={20} height={20} />,
    showTrailingIcon: true,
  };

  const handleCtaClick = () => {
    switch (commonKybStatus) {
      case StatusType.NOT_INITIATED:
        router.push(ROUTES_PATH.KYB);
        break;
      case StatusType.INITIATED:
        router.push(ROUTES_PATH.COMPANY_DETAILS);
        break;
      case StatusType.SUCCESS:
        handleClick();
        break;
    }
  };

  return (
    <div className={wrapperClassName}>
      {lottieJson ? (
        <Player
          src={lottieJson}
          style={{ height: '200px', width: '280px', ...lottieDimensions }}
          autoplay
          keepLastFrame
        />
      ) : imageSrc ? (
        <div className={`tw-relative ${imageWrapperClassName} ${imageWrapperStyle}`}>
          <Image src={imageSrc} fill priority alt='zero-data' />
        </div>
      ) : null}

      <div className={`tw-flex tw-flex-col tw-items-center tw-mx-auto ${contentClassName}`}>
        <Text textClass={titleClassName} id={`${id}-TITLE`}>
          {title}
        </Text>
        {subTitleData && <Text textClass={subtitleClassName}>{subTitleData}</Text>}
        {showButton && !(isIndividual && isPreKyb) && (
          <Button
            buttonProps={{
              btnType: BUTTON_TYPES.PRIMARY,
              size: buttonSize,
              state: BUTTON_STATE_TYPES.DEFAULT,
              onClick: handleCtaClick,
              wrapperClass: 'tw-mt-6',
              id: `${id}_ZERO_DATA_BUTTON`,
              eventCallback: trackMixpanel,
            }}
            {...(isPreKyb ? kybDestinyButtonProps : destinyButtonProps)}
          >
            {isPreKyb ? preKybButtonText || kybButtonText : buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ZeroListData;
